import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Text, Flex, Button } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { postUploadReport, postRemoveSectionPdf } from 'services/apiServices';

const V1UploadPdf = ({
  secName,
  updatePdfState,
  setLoadingInNextButton,
  canUpdate,
  updateDocument,
  isUploadedDocument,
  removeConsolidatedSectionName,
}) => {
  const currentReportUID = useSelector((state) => state.reportUID);
  const uploadSectionName = secName;
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState(() => {
    return canUpdate ? isUploadedDocument : '';
  });
  const [fileUploadError, setFileUploadError] = useState('');
  const [uploadedFile, setUploadedFile] = useState(null);
  const openFileDialog = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onFilesAddedEvt = async (event) => {
    const files = event.target.files;
    const maxFileSize = 30 * 1024 * 1024;
    if (files.length) {
      const file = files[0];
      if (file.size > maxFileSize) {
        setFileUploadError('The File is above 30MB.');
        return;
      }
      // Check if the file is a PDF
      if (file.type === 'application/pdf') {
        setFileUploadError('')
        setUploadedFile(file); // Directly set the file object
        event.target.value = null;
      }

      event.target.value = null; // Clear the input after processing
    }
  };

  useEffect(() => {
    if (uploadedFile) {
      submitPdf();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedFile]);

  const submitPdf = async () => {
    if (!uploadedFile) return; // Ensure there's an uploaded file
    let reportRes = {
      title: uploadSectionName,
      pdfFile: uploadedFile,
      reportUID: currentReportUID.reportUID,
    };
    try {
      setLoadingInNextButton(true);
      const res = await postUploadReport(reportRes);
      if (res === 'CANNOT_READ') {
        setFileUploadError('Unable to read PDF, upload a valid PDF.');
        setUploadedFile(null);
      } else {
        setFileName(uploadedFile.name);
        if (canUpdate) {
          updateDocument(uploadSectionName, uploadedFile.name);
        }
        updatePdfState(true);
      }
      setLoadingInNextButton(false);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };
  const removeSectionPdf = async () => {
    let res = {
      reportUID: currentReportUID.reportUID,
      title: uploadSectionName,
    };
    try {
      setFileName('');
      await postRemoveSectionPdf(res);
      if (isUploadedDocument) {
        removeConsolidatedSectionName(uploadSectionName);
      }
      setUploadedFile(null);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  return (
    <Box mb="24px">
      <Text sx={{ textStyle: 'manropeHeading' }} fontSize="14px">
        {uploadSectionName}
      </Text>
      <Flex alignItems="center" mt="10px">
        <Button
          border="1px"
          borderColor="avenirSuitabilityColor.600"
          borderRadius="12px"
          bg="avenirWhite"
          color="avenirSuitabilityColor.600"
          _hover={{ bg: 'avenirWhite' }}
          onClick={openFileDialog}
        >
          Upload File
        </Button>
        <Text
          key={uploadSectionName}
          sx={{ textStyle: 'manropeHeading' }}
          fontSize="14px"
          fontWeight="400"
          ml="8px"
          letterSpacing="-0.28px"
        >
          {fileName && !fileUploadError ? (
            <Box as="span" display="inline-flex" alignItems="center">
              {fileName.length > 20 ? fileName.slice(0, 20) + '...' : fileName}{' '}
              <CloseIcon
                h="25px"
                w="11px"
                ml="8px"
                cursor="pointer"
                color="avenirGrey.400"
                onClick={removeSectionPdf}
              />
            </Box>
          ) : fileUploadError ? (
            <Text
              sx={{
                textStyle: 'manropeText',
                fontsize: '12px',
                fontWeight: '600',
                color: '#B20D30',
              }}
            >
              {fileUploadError}
            </Text>
          ) : (
            'No file selected'
          )}
        </Text>
        <input
          ref={fileInputRef}
          type="file"
          style={{ display: 'none' }}
          onChange={onFilesAddedEvt}
          accept=".pdf"
        />
      </Flex>
    </Box>
  );
};

export default V1UploadPdf;
