/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import React, { useRef, useEffect, useState } from 'react';
import {
  DocumentEditorContainerComponent,
  Toolbar,
  SectionBreakType,
  Inject,
} from '@syncfusion/ej2-react-documenteditor';
import { useSelector } from 'react-redux';
import { registerLicense } from '@syncfusion/ej2-base';
import {
  PdfBitmap,
  PdfDocument,
  PdfPageOrientation,
  PdfPageSettings,
  //   PdfSection,
  SizeF,
} from '@syncfusion/ej2-pdf-export';
import {
  /*getHeaderAndFooterData*/ postSyncEditorAnswer
} from 'services/apiServices';
import { SYNCFUSION_LICIENSE_KEY } from 'static/constants';
// Registering Syncfusion license key
registerLicense(SYNCFUSION_LICIENSE_KEY);
// DocumentEditorContainerComponent.Inject(Toolbar);
const customToolbarItems = [
  'Undo',
  'Redo',
  'Image',
  'Table',
  'PageNumber',
  'Find',
  'Hyperlink',
  'Header',
  'Footer',
];

const SuitabilitySyncEditor = ({
  data,
  isGeneratePdfOrDoc,
  storedUserData,
  makeGeneratePdfDocNull
}) => {
  const financialData = useSelector((state) => state.spr);
  const currentReportUID = useSelector((state) => state.reportUID);
  const SuitabilityProgress = financialData;
  const isReportFinished = SuitabilityProgress.reportFinished;
  const sectionNumber = SuitabilityProgress.sectionNumber;
  const [callPageBreakFunction, setCallPageBreakFunction] = useState(false);
  const [addPageBreakAfterServerAnswer, setAddPageBreakAfterServerAnswer] =
    useState(false);
  const editorRef = useRef(null);
  const container = editorRef.current;
  //UseEffect to add editor, header and footersdata
  useEffect(() => {
    setTimeout(() => {
      getHeaderFooterData();
    }, 500);
  }, []);
  useEffect(() => {
    if (sectionNumber && sectionNumber > 1 && sectionNumber !== 9999) {
      setCallPageBreakFunction(true);
    }
  }, [sectionNumber]);
  const applyPageBreak = () => {
    if (
      sectionNumber &&
      sectionNumber > 1 &&
      sectionNumber !== 9999 &&
      addPageBreakAfterServerAnswer
    ) {
      const editor = editorRef.current?.documentEditor;
      editor.editor.insertSectionBreak(SectionBreakType.NewPage);
      setCallPageBreakFunction(false);
      setAddPageBreakAfterServerAnswer(false);
    } else {
      setCallPageBreakFunction(false);
      setAddPageBreakAfterServerAnswer(false);
    }
  };
  const applyLineHeight = () => {
    if (editorRef.current) {
      const editor = editorRef.current?.documentEditor;
      editor.selection.selectAll();
      editor.selection.paragraphFormat.lineSpacingType = 'Multiple';
      editor.selection.paragraphFormat.lineSpacing = 1.3;
      editor.selection.moveToDocumentEnd();
      editor.selection.moveToNextLine();
    }
  };
  useEffect(() => {
    if (editorRef.current) {
      const editor = editorRef.current?.documentEditor;
      setTimeout(() => {
        if (editor && editor.editor) {
          if (editor.selection && editor) {
            if (isReportFinished) {
              editor.selection.goToPage(1);
              editor.selection.moveToDocumentStart();
              if (data.sfdt) {
                editor.editor.paste(data);
                saveSyncEditorState();
                applyPageBreak();
                if (callPageBreakFunction) {
                  setAddPageBreakAfterServerAnswer(true);
                }
              }
              setTimeout(() => {
                editor.selection.goToPage(1);
                editor.selection.moveToDocumentStart();
              }, 500);
            } else {
              const lastPage = editor.pageCount;
              editor.selection.goToPage(lastPage);
              editor.selection.moveToDocumentEnd();
              editor.selection.moveToNextLine();
              applyPageBreak();
              if (data.sfdt) {
                editor.editor.paste(data);
                saveSyncEditorState();
              }
              if (callPageBreakFunction) {
                setAddPageBreakAfterServerAnswer(true);
              }
            }
            applyLineHeight();
          }
        }
      }, 0);
    }
  }, [data]);
  const insertPageNumbers = (documentEditor) => {
    documentEditor.selection.goToFooter();
    // Optionally add text like "Page " before the number
    documentEditor.editor.insertPageNumber();
    // Apply alignment if necessary
    documentEditor.selection.paragraphFormat.textAlignment = 'Right';
    // Move out of the header/footer section
    documentEditor.selection.closeHeaderFooter();
  };
  useEffect(() => {
    const documentEditor = editorRef.current.documentEditor;
    // Enable headers and footers
    documentEditor.enableHeaderAndFooter = true;
    // Load a blank document or your document content here
    documentEditor.openBlank();
    // Insert page numbers when a new page is added
    insertPageNumbers(documentEditor);
  }, []);
  useEffect(() => {
    if (editorRef.current) {
      const editor = editorRef.current?.documentEditor;
      // Ensure the editor is loaded
      if (editor && editor.editor) {
        //add data to editor
        // setTimeout(() => {
        if (editor.selection && editor) {
          // Move the selection to the end of the document
          const lastPage = editor.pageCount; // Get the total number of pages
          editor.selection.goToPage(lastPage); // Go to the last page
          // editor.selection.moveToLastLineInView();
          editor.selection.moveToDocumentEnd();
          editor.selection.moveToNextLine();
          if (storedUserData) {
            editor.editor.paste(storedUserData);
            saveSyncEditorState();
          }
          applyLineHeight();
          editor.selection.goToPage(1);
          editor.selection.moveToDocumentStart();
        }
        // }, 1000);
      }
    }
  }, [storedUserData]);
  //psot syncEditor Answer
  const saveSyncEditorState = async () => {
    let sfdtData = editorRef.current?.documentEditor?.serialize();
    const resbody = {
      reportUID: currentReportUID.reportUID,
      data: sfdtData,
    };
    const editor = editorRef.current?.documentEditor;
    editor.selection.moveToNextLine();
    postSyncEditorAnswer(resbody);
  };
  //Onclick to Generate Documents
  const getHeaderFooterData = async () => {
    // const editor = editorRef.current?.documentEditor;
    // const res = await getUserSfdtHeader();
    // if (editor && res?.header?.sfdt?.length) {
    //   editor.selection.goToHeader();
    //   editor.selection.paragraphFormat.textAlignment = 'Right';
    //   editor.editor.paste(res.header);
    //   editor.selection.sectionFormat.headerDistance = 10;
      // editor.selection.goToFooter();
      // editor.editor.paste(res.footer);
    //   editor.selection.closeHeaderFooter();
    // }
  };
  useEffect(() => {
    if (isGeneratePdfOrDoc === 'DOC') {
      editorRef.current.documentEditor.save(
        'avenir_suitability_report',
        'Docx',
      );
      setTimeout(() => {
        makeGeneratePdfDocNull()
      },[1000])
    }
    if (isGeneratePdfOrDoc === 'PDF') {
      let obj = container;
      let pdfdocument = new PdfDocument();
      let count = obj.documentEditor.pageCount;
      obj.documentEditor.documentEditorSettings.printDevicePixelRatio = 2;
      let loadedPage = 0;
      for (let i = 1; i <= count; i++) {
        // eslint-disable-next-line no-loop-func
        setTimeout(() => {
          // Getting pages as image
          let image = obj.documentEditor.exportAsImage(i, 'image/jpeg');
          image.onload = function () {
            let imageHeight = parseInt(
              image.style.height.toString().replace('px', ''),
            );
            let imageWidth = parseInt(
              image.style.width.toString().replace('px', ''),
            );
            let section = pdfdocument.sections.add();
            let settings = new PdfPageSettings(0);
            if (imageWidth > imageHeight) {
              settings.orientation = PdfPageOrientation.Landscape;
            }
            settings.size = new SizeF(imageWidth, imageHeight);
            section.setPageSettings(settings);
            let page = section.pages.add();
            let graphics = page.graphics;
            let imageStr = image.src.replace('data:image/jpeg;base64,', '');
            let pdfImage = new PdfBitmap(imageStr);
            graphics.drawImage(pdfImage, 0, 0, imageWidth, imageHeight);
            loadedPage++;
            if (loadedPage === count) {
              // Exporting the document as pdf
              pdfdocument.save(
                (obj.documentEditor.documentName === ''
                  ? 'avenir_suitability_report'
                  : obj.documentEditor.documentName) + '.pdf',
              );
            }
          };
        }, 500);
        setTimeout(() => {
          makeGeneratePdfDocNull()
        },[1000])
      }
    }
  }, [isGeneratePdfOrDoc]);
  // Function to apply inline styles to toolbar items
  const applyToolbarStyles = () => {
    const toolbarItems = document.querySelectorAll(
      '.e-de-toolbar .e-toolbar-item',
    );
    if (toolbarItems) {
      toolbarItems.forEach((item) => {
        item.style.paddingRight = '25px';
      });
    }
  };
  const setDefaultSyncfusionStyles = () => {
    if (editorRef.current) {
      const container = editorRef.current.documentEditor;

      // Define the styles as JSON strings
      const styles = [
        JSON.stringify({
          name: 'Heading 1',
          type: 'Paragraph',
          characterFormat: {
            fontFamily: 'Arial',
            fontSize: 20,
            bold: false,
            italic: false,
            underline: 'None',
            fontColor: '#000000',
          },
          paragraphFormat: {
            textAlignment: 'Left',
            beforeSpacing: 12,
            afterSpacing: 8,
            lineSpacing: 1.5,
          },
        }),
        JSON.stringify({
          name: 'Heading 2',
          type: 'Paragraph',
          characterFormat: {
            fontFamily: 'Arial',
            fontSize: 16,
            bold: false,
            italic: false,
            underline: 'None',
            fontColor: '#000000',
          },
          paragraphFormat: {
            textAlignment: 'Left',
            beforeSpacing: 12,
            afterSpacing: 8,
            lineSpacing: 1.5,
          },
        }),
        JSON.stringify({
          name: 'Heading 3',
          type: 'Paragraph',
          characterFormat: {
            fontFamily: 'Arial',
            fontSize: 14,
            bold: true,
            italic: false,
            underline: 'None',
            fontColor: '#000000',
          },
          paragraphFormat: {
            textAlignment: 'Left',
            beforeSpacing: 12,
            afterSpacing: 8,
            lineSpacing: 1.5,
          },
        }),
        JSON.stringify({
          name: 'Heading 7',
          type: 'Paragraph',
          characterFormat: {
            fontFamily: 'Arial',
            fontSize: 9,
            bold: false,
            italic: false,
            underline: 'None',
            fontColor: '#000000',
          },
          paragraphFormat: {
            textAlignment: 'Left',
            beforeSpacing: 12,
            afterSpacing: 8,
            lineSpacing: 1.5,
          },
        }),
        JSON.stringify({
          name: 'Normal',
          type: 'Paragraph',
          characterFormat: {
            fontFamily: 'Arial',
            fontSize: 12,
            bold: false,
            italic: false,
            underline: 'None',
            fontColor: '#000000',
          },
          paragraphFormat: {
            textAlignment: 'Left',
            beforeSpacing: 12,
            afterSpacing: 8,
            lineSpacing: 1.5,
          },
        }),
      ];

      // Add each style individually
      styles.forEach((style) => {
        container.editor.createStyle(style, true);
      });
    }
  };
  // Apply styles after the component is rendered
  useEffect(() => {
    setTimeout(setDefaultSyncfusionStyles, 200);
    setTimeout(applyToolbarStyles, 100);
  }, []);
  return (
    // <Box>
    <DocumentEditorContainerComponent
      id="container"
      height="100%"
      ref={editorRef}
      style={{ height: '90% !important' }}
      serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/documenteditor/"
      enableToolbar={true}
      showPropertiesPane={false}
      toolbarItems={customToolbarItems}
    >
      <Inject services={[Toolbar]} width="100%" />
    </DocumentEditorContainerComponent>
  );
};
export default SuitabilitySyncEditor;
