/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Flex,
  Text,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  List,
  ListItem,
  IconButton,
  InputRightElement,
  Alert,
  AlertIcon,
  Spinner,
} from '@chakra-ui/react';
import { SearchIcon, CloseIcon } from '@chakra-ui/icons';
import SkipValidateAnswer from './SkipValidateAnswer';
import PortfolioBeta from './PortfolioBeta';
import { postIsinResults, getIsinOngoingCharge } from 'services/apiServices';
import '../../../assets/css/App.css';

export default function Default({ question, userAnswered }) {
  const [portfolioState, setPortfolioState] = useState('1');
  const [isins, setIsins] = useState([
    {
      searchTerm: '',
      searchResults: [],
      selectedItem: null,
      lastSearchTerm: '',
    },
  ]);
  const [isinWeights, SetIsinWeights] = useState([]);
  const [isinOnCharge, setIsinOnCharge] = useState([]);
  const [showErrorState, setShowErrorState] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorState, setErrorState] = useState(0);
  const debounceTimeoutRef = useRef([]);
  const [searchIconIndex, setSearchIconIndex] = useState(-1);
  const changePortfolioState = () => {
    setPortfolioState(2);
  };
  const myOwnGraph = () => {
    skipVladateQuestion()
  }
  const vallidateAnswer = () => {
    let ischarngeGZero = Object.values(isinOnCharge).length;
    let res = [];
    let weightSum = 0;
    let userans = {};
    let count = 0;
    Object.keys(isinWeights).forEach((key) => {
      weightSum += parseInt(isinWeights[key], 10);
    });
    Object.values(isinOnCharge).forEach((value) => {
      // const result = parseInt(value?.slice(0, -1) || '', 10);
      if (value === '%') {
        ischarngeGZero = false;
      }
    });
    if (
      weightSum === 100 &&
      ischarngeGZero &&
      Object.values(isinOnCharge).length === isins.length &&
      Object.values(isinWeights).length === isins.length
    ) {
      isins.forEach((isin, index) => {
        if (isin.selectedItem) {
          let temp = {
            isin: isin.selectedItem.ISIN,
            weight: isinWeights[index]?.slice(0, -1) || '',
            ongoingCharge: isinOnCharge[index]?.slice(0, -1) || '',
          };
          res.push(temp);
        }
      });
      userans = { data: res };
    }
    isins.forEach((isin, index) => {
      if (isin.selectedItem) {
        count = count + 1;
      }
    });
    if (userans?.data?.length && count === isins.length) {
      setErrorState(0);
      userAnswered(userans);
    } else {
      setShowErrorState(true);
      const error = validateInput(count, weightSum, ischarngeGZero);
      setErrorMessage(error);
    }
  };
  const skipVladateQuestion = () => {
    const userans = {};
    userAnswered(userans);
  };
  const validateInput = (count, weightSum, ischarngeGZero) => {
    switch (true) {
      case count === 0 || count !== isins.length:
        setErrorState(1);
        return 'Oops! We couldn’t find your ISIN';
      case weightSum !== 100 ||
        !(Object.values(isinWeights).length === isins.length):
        setErrorState(2);
        return 'Oops! Total weight is not 100%';
      case !ischarngeGZero ||
        !(Object.values(isinOnCharge).length === isins.length):
        setErrorState(3);
        return 'Check format of Ongoing Charge';
      default:
        setErrorState(1);
        return 'Oops! We couldn’t find your ISIN';
    }
  };

  const skipQuestion = () => {
    console.log('skipQuestion');
  };

  const fetchSearchResults = async (query, index) => {
    try {
      let res = { isin: query };
      const IsinResults = await postIsinResults(res);
      setIsins((currentIsins) =>
        currentIsins.map((isin, idx) =>
          idx === index
            ? {
                ...isin,
                searchResults: IsinResults.data,
                lastSearchTerm: query,
              }
            : isin,
        ),
      );
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
    setSearchIconIndex(-1);
  };

  useEffect(() => {
    isins.forEach((isin, index) => {
      // Clear any existing timeout for this index
      if (debounceTimeoutRef.current[index]) {
        clearTimeout(debounceTimeoutRef.current[index]);
      }
      // Set a new timeout for this index
      if (
        isin.searchTerm !== isin.lastSearchTerm &&
        isin.searchTerm.length >= 1
      ) {
        setSearchIconIndex(index);
        const delay = isin.searchTerm.length === 3 ? 1000 : 1000;
        debounceTimeoutRef.current[index] = setTimeout(() => {
          fetchSearchResults(isin.searchTerm, index);
        }, delay);
      } else {
        setSearchIconIndex(-1);
      }
    });
    // Cleanup on component unmount
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      debounceTimeoutRef.current.forEach((timeout) => clearTimeout(timeout));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isins.map((isin) => isin.searchTerm)]);

  const handleSelectItem = async (index, item) => {
    setIsins((currentIsins) =>
      currentIsins.map((isin, idx) =>
        idx === index
          ? { ...isin, selectedItem: item, searchResults: [] }
          : isin,
      ),
    );
    const res = await getIsinOngoingCharge(item.ISIN);
    if (res.ongoing_charge.length) {
      handleChanrgeChange(index, res.ongoing_charge);
    }
  };

  const clearSelection = (index) => {
    setShowErrorState(false);
    setErrorState(0);
    setIsins((currentIsins) =>
      currentIsins.map((isin, idx) =>
        idx === index
          ? { ...isin, selectedItem: null, searchTerm: '', searchResults: [] }
          : isin,
      ),
    );
  };

  const handleSearchTermChange = (index, value) => {
    setShowErrorState(false);
    setErrorState(0);
    setIsins((currentIsins) =>
      currentIsins.map((isin, idx) =>
        idx === index ? { ...isin, searchTerm: value } : isin,
      ),
    );
    if (value === '') {
      setIsins((currentIsins) =>
        currentIsins.map((isin, idx) =>
          idx === index ? { ...isin, searchResults: [] } : isin,
        ),
      );
    }
  };
  const handleWeightChange = (index, value) => {
    setShowErrorState(false);
    setErrorState(0);
    const numericValue = value.replace(/%/g, '');
    if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= 100) {
      const percentageValue = numericValue + '%';
      SetIsinWeights((prevWeights) => ({
        ...prevWeights,
        [index]: percentageValue,
      }));
    }
  };
  const handleChanrgeChange = (index, value) => {
    setShowErrorState(false);
    const val = value.replace(/%/g, '');
    if (!isNaN(val) && val >= 0 && val <= 100) {
      const percentageValue = val + '%';
      setIsinOnCharge((prevCharge) => ({
        ...prevCharge,
        [index]: percentageValue,
      }));
    }
  };
  const addIsin = () => {
    setShowErrorState(false);
    setErrorState(0);
    setIsins([
      ...isins,
      {
        searchTerm: '',
        searchResults: [],
        selectedItem: null,
        lastSearchTerm: '',
      },
    ]);
    handleChanrgeChange(Object.values(isinOnCharge).length, '');
  };

  const resetAll = () => {
    setShowErrorState(false);
    setErrorState(0);
    setIsins([
      {
        searchTerm: '',
        searchResults: [],
        selectedItem: null,
        lastSearchTerm: '',
      },
    ]);
    SetIsinWeights([]);
    setIsinOnCharge([]);
  };
  const setAiSearchedIsins = () => {
    if (question?.answers[0]?.AIAnswer?.data) {
      question.answers[0].AIAnswer.data.forEach((item, index) => {
        if (index > 0) {
          addIsin();
        }
        setTimeout(() => {
          let searchedIsin = {
            ISIN: item.ISIN,
            fundName: item.Name,
            exchange: item.Country,
          };
          handleSelectItem(index, searchedIsin);
          handleWeightChange(index, item.Weight);
          handleChanrgeChange(index, item.OngoingCharge);
        }, 30);
      });
    }
  };
  useEffect(() => {
    setAiSearchedIsins();
  }, []);

  return portfolioState === '1' ? (
    <PortfolioBeta changePortfolioState={changePortfolioState} myOwnGraph={myOwnGraph}></PortfolioBeta>
  ) : (
    <Flex
      h="100%"
      overflow="auto"
      flexDirection="column"
      justifyContent="space-between"
      w="100%"
      className="hide-scrollbar"
    >
      <Box>
        <Flex justifyContent="flex-start" alignItems="center">
          <Text sx={{ textStyle: 'manropeHeading' }} fontSize="18px">
            {question.text}
          </Text>
          <Text
            color="white"
            bg="avenirOrange.600"
            sx={{ textStyle: 'manropeText' }}
            fontSize="10px"
            fontWeight="400"
            lineHeight="18px"
            p="1px 10px"
            borderRadius="8px"
            ml="5px"
          >
            {' '}
            In Beta
          </Text>
        </Flex>
        <Text
          sx={{ textStyle: 'manropeHeading' }}
          fontSize="14px"
          color="avenirGrey.400"
          lineHeight="16px"
          fontWeight="400"
          mt="12px"
        >
          {question.subText}
        </Text>
        <Flex
          className="portfolioQuestionHeadings"
          width="100%"
          justifyContent="space-between"
          mt="20px"
        ></Flex>
        {isins.map((isin, index) => (
          <Flex
            key={index}
            className="portfolioQuestion"
            w="95%"
            justifyContent="space-between"
            flexDirection="column"
            mb="40px"
          >
            <Flex>
              <Box w="100%" mb="8px">
                <Text sx={{ textStyle: 'manropeHeading' }} fontSize="14px">
                  ISIN or Asset Name
                </Text>
              </Box>
            </Flex>
            <Box w="100%">
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  {searchIconIndex === index ? (
                    <Spinner
                      thickness="2px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="avenirSuitabilityColor.800"
                      width="15px" // Set custom width
                      height="15px" // Set custom height
                    />
                  ) : (
                    <SearchIcon color="gray.300" />
                  )}
                </InputLeftElement>
                <Input
                  placeholder="Search for ISIN of Asset Name"
                  value={isin.selectedItem?.fundName || isin.searchTerm}
                  onChange={(e) =>
                    handleSearchTermChange(index, e.target.value)
                  }
                  pr="4.5rem"
                  sx={{ textStyle: 'manropeHeading' }}
                  fontSize="14px"
                  fontWeight="400"
                  borderRadius="14px"
                  ml="3px"
                  _focus={{ borderColor: '#4E13C3' }}
                  borderColor={errorState === 1 ? '#E4103F' : '#E6E6E6'}
                />
                {isin.selectedItem && (
                  <InputRightElement>
                    <IconButton
                      icon={<CloseIcon />}
                      onClick={() => clearSelection(index)}
                      variant="ghost"
                      aria-label="Clear selection"
                      _hover={{ bg: 'none' }}
                    />
                  </InputRightElement>
                )}
              </InputGroup>
              {isin.searchResults.length > 0 && (
                <List
                  spacing={2}
                  mt={2}
                  boxShadow="base"
                  p={2}
                  bg="white"
                  borderRadius="md"
                  height="80px"
                  overflow="auto"
                  ml="2px"
                >
                  {isin.searchResults.map((item, idx) => (
                    <ListItem
                      key={idx}
                      cursor="pointer"
                      _hover={{ bg: 'gray.100' }}
                      onClick={() => handleSelectItem(index, item)}
                    >
                      {item.fundName} - {item.ISIN} - {item.exchange}
                    </ListItem>
                  ))}
                </List>
              )}
            </Box>
            <Flex justifyContent="space-between" mt="18px" mb="8px">
              <Box w="45%">
                <Text sx={{ textStyle: 'manropeHeading' }} fontSize="14px">
                  Weight (in %)
                </Text>
              </Box>
              <Box w="45%">
                <Text sx={{ textStyle: 'manropeHeading' }} fontSize="14px">
                  Ongoing charge (in %)
                </Text>
              </Box>
            </Flex>
            <Flex justifyContent="space-between">
              <Box w="45%">
                <Input
                  placeholder="100%"
                  borderRadius="14px"
                  value={isinWeights[index] || ''}
                  onChange={(e) => handleWeightChange(index, e.target.value)}
                  ml="3px"
                  _focus={{ borderColor: '#4E13C3' }}
                  borderColor={errorState === 2 ? '#E4103F' : '#E6E6E6'}
                />
              </Box>
              <Box w="45%">
                <Input
                  placeholder="0.1%"
                  borderRadius="14px"
                  value={isinOnCharge[index] || ''}
                  onChange={(e) => handleChanrgeChange(index, e.target.value)}
                  ml="3px"
                  _focus={{ borderColor: '#4E13C3' }}
                  borderColor={errorState === 3 ? '#E4103F' : '#E6E6E6'}
                />
              </Box>
            </Flex>
          </Flex>
        ))}
        <Flex mt="26px" mb="10px">
          <Button
            sx={{ textStyle: 'medium' }}
            bg="avenirSuitabilityColor.600"
            _hover={{ bg: 'avenirSuitabilityColor.600' }}
            color="white"
            borderRadius="12px"
            onClick={addIsin}
          >
            Add ISIN
          </Button>
          <Button
            sx={{ textStyle: 'medium' }}
            color="avenirGrey.400"
            borderRadius="12px"
            ml="10px"
            border="1px"
            borderColor="avenirGrey.400"
            bg="white"
            _hover={{ bg: 'white' }}
            onClick={resetAll}
          >
            Reset All
          </Button>
        </Flex>
      </Box>
      <Flex flexDirection="row-reverse">
        {showErrorState ? (
          <Alert
            status="error"
            bg="#FED7D7"
            borderRadius="10px"
            sx={{ textStyle: 'manropeHeading' }}
            fontWeight="500"
          >
            <AlertIcon color="#E53E3E" />
            {errorMessage}
          </Alert>
        ) : (
          <Flex>
            <Button
              variant="light"
              fontSize="sm"
              borderRadius="12px"
              p="8px 18px"
              marginRight="10px"
              bg="avenirWhite"
              color="avenirSuitabilityColor.600"
              _hover={{ bg: 'avenirWhite' }}
              border="1px"
              borderColor="avenirSuitabilityColor.600"
              onClick={skipVladateQuestion}
            >
              Beta is meh, use my own graph
            </Button>
            <SkipValidateAnswer
              skipQuestion={skipQuestion}
              vallidateAnswer={vallidateAnswer}
            />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}
