/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Box, Flex } from '@chakra-ui/react';
//components
import V1UploadComponent from '../components/V1UploadComponent';
import V1UploadComponentConsolidate from '../components/V1UploadComponentConsolidate';
import V1SideNav from '../components/V1SideNav';
//Apis
import { getUploadPdfDetails } from 'services/apiServices';

const Dashboard = ({ sectinoChange, newReportDetails }) => {
  const [reportNumber, setReportNumber] = useState('')
  const sectionUploadChange = (value) => {
    sectinoChange(value);
  };
  const [uploadPdfsectiondetails, setUploadPdfsectiondetails] = useState();
  const fetchData = async () => {
    try {
      const response = await getUploadPdfDetails(newReportDetails.templateUID);
      setUploadPdfsectiondetails(response);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    setReportNumber(newReportDetails.templateUID )
    fetchData();
  }, []);
  return (
    <Box>
      <Flex h="100%" overflow="auto">
        {uploadPdfsectiondetails?.length ? (
          <Flex>
            <V1SideNav></V1SideNav>
            <Flex w="80vw" justifyContent="center">
              {reportNumber === '733' ||  reportNumber === 733 || reportNumber === '731' ||  reportNumber === 731? (
                <V1UploadComponent
                  sectionUploadChange={sectionUploadChange}
                  uploadPdfsectiondetails={uploadPdfsectiondetails}
                ></V1UploadComponent>
              ):null}
             {reportNumber === '734' || reportNumber === 734 || reportNumber === '732' ||  reportNumber === 732 ? (
                <V1UploadComponentConsolidate
                  sectionUploadChange={sectionUploadChange}
                  uploadPdfsectiondetails={uploadPdfsectiondetails}
                ></V1UploadComponentConsolidate>
              ): null}
            </Flex>
          </Flex>
        ) : null}
      </Flex>
    </Box>
  );
};
export default Dashboard;
